<template>
  <div class="loginPageBox">
    <div v-if="$route.query.token" class="login-box-shadow ">
      <p><i class="el-icon-loading"> 正在加载中...</i></p>
    </div>
    <!-- 登录的顶部 -->
    <div v-else>
      <div class="loginTop">
        <div class="typeArea">
          <!-- logo -->
          <h1 class="logo">
            <router-link :to="{ path: '/' }">
              <img :src="$localPicture+'logo/logo_black.png'" alt="logo">
            </router-link>
          </h1>
          <!-- 欢迎登录 -->
          <p class="greet">
            欢迎登录
          </p>
        </div>
      </div>
      <!-- 大图片 -->
      <div class="loginBox">
        <img
          class="loginBanner"
          src="@/assets/image/loginBanner.png"
          alt="loginBanner"
        >
        <div class="astrictWidth">
          <div class="textDescription">
            <h3>B2B电商·专注大宗交易</h3>
            <p>全过程数字化采购管理</p>
          </div>
          <div class="login">
            <!-- 上面可点击的tab -->
            <div class="titleTab">
              <span
                v-for="(item, index) in loginTabArr"
                :key="index"
                :class="activeIndex === index ? 'active' : ''"
                @click="getActiveIndex(index)"
              >{{ item }}</span>
            </div>
            <el-form
              ref="ruleForm"
              :model="ruleForm"
              :rules="rules"
              class="demo-ruleForm"
            >
              <!-- 账户密码登录 -->
              <div v-if="activeIndex === 0" class="passwordLogin">
                <el-form-item class="account" label="用户名" prop="userCode">
                  <el-input
                    v-model.trim="ruleForm.userCode"
                    placeholder="请输入用户名"
                  />
                </el-form-item>
                <el-form-item class="account" label="密码" prop="password2">
                  <el-input
                    v-model.trim="ruleForm.password2"
                    :type="lookPassword ? 'text' : 'password'"
                    placeholder="请输入密码"
                  />
                  <img v-if="!lookPassword" class="lookPasswordImage" src="@/assets/icon/look.png" alt="icon" @click="lookPassword=true">
                  <img v-else class="lookPasswordImage" src="@/assets/icon/possword.png" alt="icon" @click="lookPassword=false">
                </el-form-item>
                <!-- <el-form-item class="password" label="验证码" prop="kaptcha">
                  <img
                    :src="ruleForm.codeimg"
                    alt
                    class="imageCode"
                    @click="getVerificationCode()"
                  >
                  <el-input
                    v-model.trim="ruleForm.kaptcha"
                    placeholder="请输入验证码"
                  />
                </el-form-item> -->
                <slide-verify :refresh-slide="refreshSlide" @moveCheck="moveCheck" />
              </div>
              <!-- 手机号码登录 -->
              <div v-else-if="activeIndex === 1" class="mobilePhoneLogin">
                <el-form-item class="account2" label="手机号" prop="phoneNo">
                  <el-input
                    v-model.trim="ruleForm.phoneNo"
                    placeholder="请输入手机号"
                  />
                </el-form-item>
                <el-form-item class="password2" label="验证码" prop="code">
                  <span
                    :class="['code', disabled ? 'activeCode' : '']"
                    @click="getCode"
                  >{{ codeStr }}</span>
                  <el-input
                    v-model.trim="ruleForm.code"
                    type="number"
                    maxlength="6"
                    placeholder="请输入验证码"
                  />
                </el-form-item>
              </div>
              <el-form-item>
                <span class="loginButton" :class="(Object.keys(ruleForm.imageCaptchaTrackReq).length == 0 && activeIndex === 0) ? 'prohibit': 'operate'" @click="cmpLogin">登录</span>
                <router-link class="registerButton" :to="{ path: '/register' }">
                  <span>还没有账号？</span>注册账号
                </router-link>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import { encrypt } from '@/unit/index'
import { rules } from '@/unit/matchFormRegex'
import Footer from '@/components/Footer.vue'
import { sendCodeLogin, login, phoneLogin, getCodes, getSysGetUserInfo } from '@/http/login'
import SlideVerify from '@/components/SlideVerify.vue'
export default {
  components: { Footer, SlideVerify },
  data() {
    return {
      ruleForm: {
        codeimg: '',
        code: '',
        phoneNo: '',
        userCode: '',
        kaptcha: '',
        imageCaptchaTrackReq: {}
      },
      rules: {
        kaptcha: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 4, max: 4, message: '长度应为4个字符', trigger: 'blur' }
        ],
        phoneNo: [...rules.phone],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 4, max: 6, message: '长度在 4 到 6 个字符', trigger: 'blur' }
        ],
        password2: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        userCode: [
          { required: true, message: '请输入登录账号', trigger: 'blur' },
          {
            min: 5,
            max: 25,
            message: '长度在 5 到 25 个字符',
            trigger: 'blur'
          }
        ]
      },
      codeStr: '获取验证码',
      lookPassword: false,
      disabled: false,
      loginTabArr: ['账号密码登录', '手机号登录'],
      activeIndex: 0,
      timer: null,
      refreshSlide: null
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  },
  mounted() {
    if (this.$route.query.token) {
      const { token, cmpRole, wantToBuy } = this.$route.query
      this.$store.commit('setRoles', cmpRole)
      this.$store.commit('getToken', token)
      getSysGetUserInfo((res) => {
        this.$store.commit('getUserInfo', res.data)
        if (wantToBuy === '00') return this.$router.push('/buyHall/wantBuyAdd')
        this.$router.push('/')
      })
    }
    // this.getVerificationCode()
  },
  methods: {
    // 获取验证码
    getCode() {
      if (this.timer) return
      let num = 60
      if (!this.ruleForm.phoneNo || this.ruleForm.phoneNo.length !== 11) {
        this.$message.error('请填写正确的手机号码')
        return
      }
      this.disabled = true
      sendCodeLogin(this.ruleForm.phoneNo, (res) => {
        this.ruleForm.code = res.msg
        this.timer = setInterval(() => {
          if (num <= 1) {
            clearInterval(this.timer)
            this.timer = null
            this.codeStr = '获取验证码'
            this.disabled = false
            return
          }
          num--
          this.codeStr = num >= 10 ? num + 's' : '0' + num + 's'
        }, 1000)
      })
    },
    // 点击登录按钮
    cmpLogin() {
      if (Object.keys(this.ruleForm.imageCaptchaTrackReq).length === 0 && this.activeIndex === 0) return
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.ruleForm.cmpRole = '-1'
          // 账号密码登录
          if (this.activeIndex === 0) {
            this.ruleForm.password = encrypt(this.ruleForm.password2)
            const param = JSON.parse(JSON.stringify(this.ruleForm))
            delete param.password2
            login(param, (res) => {
              this.$store.commit('setRoles', res.data.cmpRole)
              this.$store.commit('getToken', res.data.token)
              this.$store.commit('getUserInfo', res.data)
              this.$message.success('登录成功')
              this.$router.push({ path: sessionStorage.beforeLoginUrl || '/' })
              setTimeout(() => {
                sessionStorage.beforeLoginUrl = ''
              }, 500)
            }, () => {
              // this.getVerificationCode()
              this.refreshSlide = String(new Date())
              this.ruleForm.imageCaptchaTrackReq = {}
            })
            return
          }
          phoneLogin(this.ruleForm, (res) => {
            this.$store.commit('setRoles', res.data.cmpRole)
            this.$store.commit('getToken', res.data.token)
            this.$store.commit('getUserInfo', res.data)
            this.$message.success('登录成功')
            this.$router.push({ path: sessionStorage.beforeLoginUrl || '/' })
            setTimeout(() => {
              sessionStorage.beforeLoginUrl = ''
            }, 500)
          }, () => {
            this.getVerificationCode()
          })
        }
      })
    },
    // 获取验证码
    getVerificationCode() {
      getCodes((res) => {
        this.ruleForm.codeimg = res.captchaBase64
        this.ruleForm.key = res.key
      })
    },
    getActiveIndex(index) {
      this.activeIndex = index
      this.$refs['ruleForm'].resetFields()
    },
    // 获取校验数据
    moveCheck(data) {
      this.ruleForm.imageCaptchaTrackReq = data
    }
  }
}
</script>

<style lang="scss" scoped>
.loginPageBox {
  .loginBox {
    position: relative;
    height: calc(100vh - 264px);
    width: 100%;

    .astrictWidth {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      width: $centerPlateWidth;
      margin: auto;
      height: 100%;
      .login {
        top: 10vh;
        width: 468px;
        box-shadow: 0 6px 30px 0 rgba(0,0,0,0.05), 0 16px 24px 0 rgba(0,0,0,0.04), 0 8px 10px 0 rgba(0,0,0,0.08);
        background-color: #fff;
        display: flex;
        align-items: center;
        flex-direction: column;
        height: auto;
        .el-form {
          width: calc(100% - 70px);

          /deep/.account {
            margin-bottom: 24px;
            position: relative;
            .lookPasswordImage{
              position: absolute;
              top: 30px;
              right: 12px;
              height: 16px;
              width: 16px;
              cursor: pointer;
              z-index: 12;
            }
          }

          /deep/.password {
            margin-bottom: 30px;
          }
          /deep/.account2 {
            margin-bottom: 28px;
          }

          /deep/.password2 {
            margin-bottom: 48px;
          }
          /deep/.el-form-item__label{
            display: block;
            width: 100%!important;
            text-align: left;
            line-height: 1;
            padding-bottom: 4px;
            color: #666666;
          }
          /deep/.el-form-item__label::before{
            content:'';
            margin-right: 0;
          }

          /deep/.el-form-item__content {
            position: relative;

            .loginButton {
              display: inline-block;
              width: 100%;
              height: 40px;
              line-height: 40px;
              color: #fff;
              background-color: $themeColors;
              cursor: pointer;
              font-size: 15px;
              text-align: center;
               &.prohibit {
                cursor: default;
                background-color: rgba(0,0,0,.2);
              }
            }

            .registerButton {
              color: $themeColors;
              font-size: 14px;
              span{
                color: #666;
              }
            }

            img {
              position: absolute;
              top: 16px;
              z-index: 12;
              height: 18px;
              width: 18px;
            }

            .code {
              position: absolute;
              top: 32px;
              right: 8px;
              display: inline;
              z-index: 12;
              line-height: 15px;
              height: 15px;
              font-size: 12px;
              color: #0089ff;
              cursor: pointer;
              border-left: 1px solid #e7e7e7;
              padding-left: 8px;
            }
            .activeCode {
              color: #ccc;
            }
            .imageCode {
              position: absolute;
              top: 18px;
              right: 0;
              height: 40px;
              width: 120px;
              z-index: 223232;
            }

            .icon {
              left: 15px;
            }

            .icon2 {
              right: 89px;
            }

            .lookImg {
              right: 18px;
              cursor: pointer;
            }
          }

          /deep/.el-input__inner {
            height: 40px;
            background-color: #fff;
            border: 1px solid #f0f0f0 !important;
          }

          /deep/.el-input__inner:focus {
            border: 1px solid #f0f0f0 !important;
          }
        }

        .titleTab {
          padding: 40px;
          width: calc(100% - 80px);
          display: flex;
          justify-content: flex-start;
          span {
            color: #666;
            font-size: 20px;
            text-align: center;
            cursor: pointer;
            border-bottom: 2px solid #fff;
            padding-bottom: 8px;
            margin-right: 32px;
          }

          .active {
            color: $themeColors;
            border-color: $themeColors;
          }
        }
      }
      .textDescription{
        h3{
          color: #333333;
          font-size: 48px;
          font-weight: 500;
        }
        p{
          color: #666666;
          font-size: 32px;
          padding: 20px 0 140px;
          font-weight: 400;
        }
      }
    }

    .loginBanner {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }

  .loginTop {
    height: 92px;
    background-color: #fff;

    .typeArea {
      display: flex;
      align-items: center;
      height: 100%;
      width: $centerPlateWidth;
      margin: auto;
      color: $fontColor4;
      font-size: 18px;
      .greet{
        font-size: 24px;
        color: #333;
        height: 40px;
        line-height: 40px;
        padding-left: 24px;
        margin-left: 24px;
        border-left: 1px solid #e7e7e7;
      }
      .logo {
        width: 237px;
        height: 60px;
        a,
        img {
          height: 100%;
          width: 100%;
          display: inline-block;
        }
      }
    }
  }
}
.login-box-shadow {
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.9);
  font-size: 28px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    display: flex;
    align-items: center;
  }
}
</style>
